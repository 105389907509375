html, body {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 2vh;
  font-weight: 300;
  color: #ffffff;
  background-color: #252525;
  text-align: center;
}

.steps {
  text-align: left;
  padding: 1em 2em;
}

p.title {
  color: #C4C4C4;
  font-size: 3vh;
}

p.step-white {
  color: #C4C4C4;
  font-size: 3vh;
  padding-top: 0.5em;
  margin-bottom: 0em;
}

.step-blue {
  color: #3D9CDE;
  font-weight: bold;
}

.info{
  color: #C2C2C2;
  font-style: italic;
  font-size: 2vh;
  padding-left: 2em;
}

.logo {
  padding-top: 10%
}

.icon {
  padding-left: 0.4em;
  margin-bottom: 0.5em;
  height: 3vh;
}

.continue {
  color: #FAFAFA;
  font-size: 2vh !important;
  border-radius: 30px !important;
  padding: 0.5em 3.8em !important;
}

.alert {
  margin-top: 1em;
  padding-bottom: 0;
  width: 100%;
  font-size: 1.8vh;
}


